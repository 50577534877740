@import './theme.scss';

[data-amplify-container] {
  padding-top: 30px !important;
}

.amplify-button:default {
  border-radius: 10px;
  background-image: $themeGradient;
  border-color: #0098d8 !important;
}

.amplify-icon {
  color: $fontColor !important;
}

.amplify-button--loading {
  color: $colorWhite !important;
}

.amplify-button:hover {
  background-image: $themeDarkGradient;
}

.amplify-button--link {
  color: $colorPrimaryDark;
}

.amplify-button--link:hover {
  background-color: #ffffff;
  background-image: none;
  border-radius: 10px;
  text-decoration: underline;
}

.amplify-heading {
  color: $fontColor !important;
}

.amplify-label {
  color: $fontColor !important;
}

.amplify-input {
  color: $fontColor !important;
}

.amplify-input:focus {
  color: $fontColor !important;
  border-color: #d2d2d2;
  box-shadow: 0 0 4px $fontColor;
}

.amplify-tabs {
  border-color: #d2d2d2;
}

.amplify-tabs__item {
  color: $colorWhite;
  border-color: transparent !important;
  background: $themeGradient;
}

.amplify-tabs__item--active {
  color: $fontColor;
  background: transparent;
}

.amplify-tabs__item:hover {
  color: $fontColor;
}
