@import './theme.scss';

:root {
  --toastify-color-success: #{$colorSuccess};
  --toastify-color-info: #{$colorInfo};
  --toastify-color-warning: #{$colorWarning};
  --toastify-color-error: #{$colorError};
}

.Toastify__toast,
.Toastify__toast-body {
  font-family: $fontFamily;
}
